import React from "react";
import Svg from "../Svg";
import { SvgProps } from "../types";

const FacebookIcon: React.FC<SvgProps> = (props) => {
  const { fill } = props;
  return (
    <Svg
      width="24"
      height="24"
      viewBox="0 0 24 24"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      {...props}
      onClick={() => window.open("https://www.facebook.com/UM3taverse")}
    >
      <path
        d="M22 12C22 6.47715 17.5229 2 12 2C6.47715 2 2 6.47715 2 12C2 16.9912 5.65684 21.1283 10.4375 21.8785V14.8906H7.89844V12H10.4375V9.79688C10.4375 7.29063 11.9305 5.90625 14.2146 5.90625C15.3084 5.90625 16.4531 6.10156 16.4531 6.10156V8.5625H15.1922C13.95 8.5625 13.5625 9.3334 13.5625 10.125V12H16.3359L15.8926 14.8906H13.5625V21.8785C18.3432 21.1283 22 16.9912 22 12Z"
        fill={`${fill ? fill : "#04D9FF"}`}
      />
    </Svg>
  );
};

export default FacebookIcon;
