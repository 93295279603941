import axios from "axios";
import { BASE_URL } from "config";

export const otpVerify = async (userData) => {
  return new Promise((resolve, reject) => {
    axios
      .post(`${BASE_URL}/user/otp/verify`, {
        ...userData,
      })
      .then((res) => {
        resolve(res);
      })
      .catch((err) => {
        reject(err);
      });
  });
};
