import React from "react";
import Svg from "../Svg";
import { SvgProps } from "../types";

const ProfileIcon: React.FC<SvgProps> = (props) => {
  return (
    <Svg
      width="20"
      height="22"
      viewBox="0 0 20 22"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      {...props}
    >
      <path
        d="M18.8012 20.9001C18.8012 19.365 18.8012 18.5974 18.6117 17.9728C18.1851 16.5666 17.0847 15.4661 15.6784 15.0396C15.0539 14.8501 14.2863 14.8501 12.7512 14.8501H7.25118C5.71605 14.8501 4.94849 14.8501 4.32392 15.0396C2.91767 15.4661 1.81722 16.5666 1.39064 17.9728C1.20117 18.5974 1.20117 19.365 1.20117 20.9001M14.9512 6.0501C14.9512 8.78391 12.735 11.0001 10.0012 11.0001C7.26736 11.0001 5.05117 8.78391 5.05117 6.0501C5.05117 3.31629 7.26736 1.1001 10.0012 1.1001C12.735 1.1001 14.9512 3.31629 14.9512 6.0501Z"
        stroke="white"
        strokeWidth="2"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
    </Svg>
  );
};

export default ProfileIcon;
