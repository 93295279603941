import styled from "styled-components";

interface ILoaderProps {
  size?: string;
  color?: string;
}

const Loader = (props: ILoaderProps) => {
  return (
    <LoaderWrapper>
      <svg
        xmlns="http://www.w3.org/2000/svg"
        width="24"
        height="24"
        viewBox="0 0 24 24"
        strokeWidth="2"
        stroke={props.color || "#E22E31"}
        fill="none"
        strokeLinecap="round"
        strokeLinejoin="round"
        fontSize={props.size || "20px"}
      >
        <path stroke="none" d="M0 0h24v24H0z" fill="none"></path>
        <path d="M12 3a9 9 0 1 0 9 9"></path>
      </svg>
    </LoaderWrapper>
  );
};

const LoaderWrapper = styled.div`
  @keyframes spinner {
    0% {
      transform: rotate(0deg);
    }
    100% {
      transform: rotate(360deg);
    }
  }
  display: inline-block;
  margin: 0 6px 4px 0;

  svg {
    animation: spinner 1s linear infinite;
  }
`;

export default Loader;
