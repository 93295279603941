import React, { useEffect, useState } from "react";
import styled from "styled-components";
import Container from "../layout/Container";
import { NavLink } from "react-router-dom";
import Nav from "./components/Nav";
import MobileNavbar from "components/MobileNavbar";
import { useHistory } from "react-router-dom";
import { StaticRoutes } from "utils/staticRoutes";
import { useContext } from "react";
import { AuthContext } from "contexts/AuthContext";
import DropDown from "./components/DropDown";
import { UMetaModal } from "./components/UMetaModal";

interface NavbarProps {
  onPresentMobileMenu: () => void;
  onCloseMobileMenu: () => void;
  mobileMenu: boolean;
}

const Navbar: React.FC<NavbarProps> = ({
  onPresentMobileMenu,
  onCloseMobileMenu,
  mobileMenu,
}) => {
  const history = useHistory();

  const { user } = useContext(AuthContext);

  const [scrollPosition, setScrollPosition] = useState(0);
  const [showModal, setShowModal] = useState(false);
  const handleScroll = () => {
    const position = window.pageYOffset;

    setScrollPosition(position);
  };

  useEffect(() => {
    window.addEventListener("scroll", handleScroll, { passive: true });

    return () => {
      window.removeEventListener("scroll", handleScroll);
    };
  }, []);

  const Logout = () => {
    localStorage.removeItem(":user");
    window.location.reload();
  };

  React.useEffect(() => {
    document
      .querySelectorAll(".button")
      .forEach(
        (button) =>
          (button.innerHTML =
            "<div><span>" +
            button.textContent.trim().split("").join("</span><span>") +
            "</span></div>")
      );
  }, []);

  return (
    <NavbarFixedTop
      className={`${scrollPosition > 30 && "bg-gardient"} ${
        mobileMenu && "bg-black"
      }`}
    >
      <MobileNavbar onDismiss={onCloseMobileMenu} visible={mobileMenu} />

      <Container className="no-Background p-0">
        <NavbarInner>
          <NavLogo exact to="/" activeClassName="">
            <img src="./assets/images/Umeta/logo.svg" alt="" />
          </NavLogo>
          <NavbarLinks>
            <Nav />
            {user && (
              <DropDown
                onProfile={() => history.push(StaticRoutes.PROFILE)}
                onLogout={Logout}
              />
            )}
            <button
              onClick={() => {
                setShowModal(true);
              }}
            >
              Go U-Meta
            </button>
          </NavbarLinks>
          <UMetaModal show={showModal} onHide={() => setShowModal(false)} />

          <MobileNavbarIcon
            onClick={mobileMenu ? onCloseMobileMenu : onPresentMobileMenu}
          >
            <img src={`./assets/images/mobileNavIcon.png`} alt="" />
          </MobileNavbarIcon>
        </NavbarInner>
      </Container>
    </NavbarFixedTop>
  );
};

export default Navbar;

const NavbarFixedTop = styled.div`
  position: fixed;
  top: 0;
  width: 100%;
  z-index: 97;
  &.bg-gardient {
    background: #000000;
  }
`;

const NavbarInner = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 10px 0px;
  ${({ theme }) => theme.mediaQueries.maxMd} {
    padding: 10px 18px;
  }
`;
const NavLogo = styled(NavLink)`
  ${({ theme }) => theme.mediaQueries.maxMd} {
    img {
      width: 85%;
    }
  }
`;
const MobileNavbarIcon = styled.div`
  display: none;
  padding-top: 8px;
  cursor: pointer;
  ${({ theme }) => theme.mediaQueries.maxMd} {
    display: block;
  }
`;
const NavbarLinks = styled.div`
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
  ${({ theme }) => theme.mediaQueries.maxMd} {
    display: none;
  }
  button {
    margin-right: 15px;
    font-family: "Montserrat";
    font-style: normal;
    font-weight: 700;
    font-size: 18px;
    line-height: 130%;
    display: flex;
    align-items: center;
    text-align: center;
    justify-content: center;
    outline: 0;
    border: 0;
    background: transparent;
    border-radius: 8px;
    width: 20%;
    height: 44px;
    // padding:12px 0;
    color: #04d9ff;
    transform: translateY(-4px) translateZ(0);
    transition: all ease-in-out 0.3s;
    border: 1px solid #04d9ff;

    @media (max-width: 1024px) {
      width: 28%;
    }
    &:hover {
      background: #04d9ff;
      border: transparent;
      color: #000000;
    }
  }

  .wallet-button {
    color: ${({ theme }) => theme.colors.text};
    font-family: "Montserrat";
    font-style: normal;
    font-weight: 700;
    font-size: 16px;
    line-height: 130%;
    :hover {
      background: transparent;
      color: ${({ theme }) => theme.colors.text};
      opacity: 1;
      border: 1px solid ${({ theme }) => theme.colors.primary};
    }
  }
`;
