import { createGlobalStyle } from "styled-components";

const GlobalStyle = createGlobalStyle`
iframe{
  pointer-events: none;
}
  * {
    font-family: sans-serif;
  }

  input:-webkit-autofill,
  input:-webkit-autofill:hover,
  input:-webkit-autofill:focus,
  input:-webkit-autofill:active {
      transition: background-color 5000s ease-in-out 0s;
       background: transparent !important;
       -webkit-text-fill-color: inherit !important;
  }
  
  
  body {
    background: ${({ theme }) => theme.colors.background};
    overflow-x: hidden;
    position: relative;
    img {
      height: auto;
      max-width: 100%;
    }
    .gradientTextHeading{
      text-transform: uppercase;
      /* Warning: no fallback */
      background: linear-gradient(270deg, #04D9FF -10.55%, #39FF14 113.55%);

      // background: -webkit-linear-gradient(-86deg, #04D9FF 5%, #7AEC8D 53%, #09E5C3 91%);
      -webkit-background-clip: text;
      -webkit-text-stroke: 4px transparent;
      color: black;
      ${({ theme }) => theme.mediaQueries.maxMd} {
        font-size:100px !important;
        text-align: center;
      }

    }
    // @media screen and (min-width: 768px) {
    //   .swiper-container {
    //     width: 768px !important;
    //   }
    // }
        
        a {
              color: inherit;
              text-decoration: none;
              :hover {
                    color: inherit;
              }
        }
  }
`;

export default GlobalStyle;
