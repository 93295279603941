import { Colors } from "./types";

export const baseColors = {
  failure: "#ED4B9E",
  primary: "#1FC7D4",
  primaryBright: "#53DEE9",
  primaryDark: "#0098A1",
  secondary: "#7645D9",
  success: "#31D0AA",
  warning: "#FFB237",
  white: "#FFFFFF",
  black: "#000000",
  red: "#E22E31",
  grey: "#9F9F9F",
  disabledInputField: "#505050",
};

export const brandColors = {
  binance: "#F0B90B",
};

export const lightColors: Colors = {
  ...baseColors,
  ...brandColors,
  background: "black",
  backgroundDisabled: "#E22E3170",
  backgroundAlt: "#FFFFFF",
  text: "#ffffff",
  textDisabled: "#BDC2C4",
  textSubtle: "#8f80ba",
  borderColor: "#E9EAEB",
  secondaryTextColor: "#EAECF0",
  tertiaryTextColor: "#D0D5DD",
  primaryBorderColor: "#475467",
  blueText: "#04D9FF",
};

export const darkColors: Colors = {
  ...baseColors,
  ...brandColors,
  secondary: "#9A6AFF",
  background: "#100C18",
  backgroundDisabled: "#E22E3170",
  backgroundAlt: "#27262c",
  primaryDark: "#0098A1",
  text: "#452A7A",
  textDisabled: "#BDC2C4",
  textSubtle: "#8f80ba",
  borderColor: "#E9EAEB",
  secondaryTextColor: "#EAECF0",
  tertiaryTextColor: "#D0D5DD",
  primaryBorderColor: "#475467",
  blueText: "#04D9FF",
};
