import * as DropdownMenu from "@radix-ui/react-dropdown-menu";
import {
  DiamondIcon,
  DownArrow,
  LockIcon,
  LogoutIcon,
  ProfileIcon,
} from "components/Svg";
import styled from "styled-components";
import { useLocation, useHistory } from "react-router-dom";
import { useContext, useState } from "react";
import { AuthContext } from "contexts/AuthContext";
import { Text } from "components/Text";
import { Box } from "components/Box";
import { identicon } from "utils/identicon";
import PrivateKeyExportModal from "../../../ExportPrivateKey";

type IDropDownProps = {
  onLogout: () => void;
  onProfile: () => void;
};

const DropDown = ({ onLogout, onProfile }: IDropDownProps) => {
  const location = useLocation();
  const history = useHistory();
  const { user } = useContext(AuthContext);
  const [privateKeyModal, setPrivateKeyModal] = useState(false);
  const [close, setClose] = useState(false);

  const image = identicon(user?.walletAddress || user?.email);
  let pictureUrl = `data:image/svg+xml,${image}`;

  return (
    <>
      <PrivateKeyExportModal
        show={privateKeyModal}
        setShow={setPrivateKeyModal}
      />
      <Root open={close} onOpenChange={setClose}>
        <Trigger>
          <Box className="box">
            <Box className="img-wrapper">
              <img
                src={user?.profilePicture ? user?.profilePicture : pictureUrl}
                alt="profile"
              />
            </Box>
            <Text className="text">
              {`${user?.walletAddress?.slice(
                0,
                3
              )}...${user?.walletAddress?.slice(-3)}`}
            </Text>
            <DownArrow />
          </Box>
        </Trigger>
        <Content>
          <Label
            onClick={onProfile}
            className={location.pathname === "/profile" ? "active" : ""}
          >
            <ProfileIcon style={{ margin: "0 10px" }} /> Profile
          </Label>
          <Label
            onClick={() => history.push("/dashboard")}
            className={location.pathname === "/dashboard" ? "active" : ""}
          >
            <DiamondIcon style={{ margin: "0 7px" }} fontSize={"24px"} /> ISLU
            Digital Collectibles
          </Label>
          {user?.isCustodian && (
            <Label
              onClick={() => {
                setPrivateKeyModal(true);
                setClose(false);
              }}
            >
              <LockIcon style={{ margin: "0 7px" }} fontSize={"24px"} /> Export
              Private Key
            </Label>
          )}
          <Label onClick={onLogout}>
            <LogoutIcon style={{ margin: "0 10px" }} /> Logout
          </Label>
        </Content>
      </Root>
    </>
  );
};

export default DropDown;

const Root = styled(DropdownMenu.Root)``;

const Trigger = styled(DropdownMenu.Trigger)`
  border: none !important;
  width: fit-content !important;

  .box {
    border: 1px solid ${({ theme }) => theme.colors.white};
    border-radius: 8px;
    display: flex;
    align-items: center;
    padding: 10px 10px 10px 4px;
    cursor: pointer;
    flex-direction: row;
    height: 44px;
  }
  .img-wrapper {
    width: 36px;
    height: 36px;
    border-radius: 8px;
    background: ${({ theme }) => theme.colors.white};
    img {
      width: 100%;
      height: 100%;
      border-radius: 8px;
    }
  }
  .text {
    color: ${({ theme }) => theme.colors.text};
    font-family: "Montserrat";
    font-style: normal;
    font-weight: 700;
    font-size: 16px;
    line-height: 130%;
    margin: 0 15px;
  }

  :hover {
    background: transparent !important;
  }
`;

const Content = styled(DropdownMenu.Content)`
  background: linear-gradient(
    147.31deg,
    rgba(94, 94, 94, 0.4) 4.28%,
    rgba(94, 94, 94, 0) 98.92%
  );
  filter: drop-shadow(0px 12px 16px rgba(0, 0, 0, 0.25));
  backdrop-filter: blur(12px);
  border: 1px solid #505050;
  border-radius: 8px;
  width: 170px;
  margin-top: 7px;
`;

const Label = styled(DropdownMenu.Label)`
  color: ${({ theme }) => theme.colors.text};
  text-align: left;
  display: flex;
  align-items: center;
  cursor: pointer;
  font-family: "Inter", sans-serif;
  font-weight: 500;
  padding: 0.6rem 0.4rem;
  border: solid 1px transparent;
  font-size: 14px;
  &.active {
    background: linear-gradient(
      96.74deg,
      rgba(0, 0, 0, 0.5) 65.74%,
      rgba(226, 46, 49, 0.5) 136.46%
    );
    border: 1px solid #e22e31;
    box-shadow: 0px 0px 12px rgba(226, 46, 49, 0.36);
    border-radius: 8px;
  }
  :hover {
    background: linear-gradient(
      96.74deg,
      rgba(0, 0, 0, 0.5) 65.74%,
      rgba(226, 46, 49, 0.5) 136.46%
    );
    border: 1px solid #e22e31;
    box-shadow: 0px 0px 12px rgba(226, 46, 49, 0.36);
    border-radius: 8px;
  }
`;
