import React, { useEffect, useState } from "react";
import styled, { keyframes } from "styled-components";
import { NavLink } from "react-router-dom";
import NavigationTabArray from "utils/navigationTab";
import DiscordIcon from "components/Svg/Icons/Discord";
import TwitterIcon from "components/Svg/Icons/Twitter";
import InstagramIcon from "components/Svg/Icons/Instagram";
import useViewport from "hooks/useViewPort";
import { Button } from "components/Button";
import { useHistory } from "react-router-dom";
import { StaticRoutes } from "utils/staticRoutes";
import { useContext } from "react";
import { AuthContext } from "contexts/AuthContext";
import PrivateKeyExportModal from "../ExportPrivateKey";
import { useLocation } from "react-router";
import { Box } from "../Box";
import { LockIcon } from "../Svg";
import useTheme from "../../hooks/useTheme";
interface MobileMenuProps {
  onDismiss: () => void;
  visible?: boolean;
}

const MobileNavbar: React.FC<MobileMenuProps> = ({ onDismiss, visible }) => {
  const { width } = useViewport();
  const history = useHistory();
  const { pathname } = useLocation();
  if (width > 852) {
    visible = false;
    onDismiss();
  }
  const { theme } = useTheme();
  const { user } = useContext(AuthContext);
  const [privateKeyModal, setPrivateKeyModal] = useState(false);

  const Logout = () => {
    localStorage.removeItem(":user");
    window.location.reload();
  };

  React.useEffect(() => {
    if (visible) {
      document.querySelector("body").style.overflowY = "hidden";
      document.getElementById("mobile-menu").scrollTo(0, 0);
    } else {
      document.querySelector("body").style.overflowY = "auto";
    }
  }, [visible]);

  useEffect(() => {
    onDismiss();
  }, [pathname, onDismiss]);

  if (visible) {
    return (
      <>
        <StyledMobileMenuWrapper id="mobile-menu">
          <StyledBackdrop />

          <StyledMobileMenu>
            <StyledLogoAndIcon></StyledLogoAndIcon>

            {NavigationTabArray.map((data, index) => (
              <>
                {user && data?.name === "ISLU Digital Collectibles" ? (
                  <>
                    <StyledLink
                      key={index}
                      exact
                      to={"/dashboard"}
                      activeClassName="active"
                      className={"padding-high"}
                    >
                      {data.name}
                    </StyledLink>
                  </>
                ) : (
                  <>
                    <StyledLink
                      key={index}
                      exact
                      to={data?.route}
                      activeClassName="active"
                      className={"padding-high"}
                    >
                      {data.name}
                    </StyledLink>
                  </>
                )}
              </>
            ))}
            {user && (
              <>
                <StyledLink
                  exact
                  to={"/profile"}
                  activeClassName="active"
                  className={"padding-high"}
                >
                  Profile
                </StyledLink>
                <StyledLink
                  exact
                  to={"/signup"}
                  activeClassName="active"
                  className={"padding-high"}
                  onClick={Logout}
                >
                  Logout
                </StyledLink>
              </>
            )}

            {user === null && (
              <Button
                onClick={() => history.push(StaticRoutes.SIGNIN)}
                style={{ marginTop: "20px" }}
              >
                Sign in
              </Button>
            )}
            <Box className={"nav-footer"}>
              {user && user?.isCustodian && (
                <Button
                  variant={"secondary"}
                  className={"my-3 w-100"}
                  onClick={() => setPrivateKeyModal(true)}
                >
                  <Box className={"me-2"}>
                    <LockIcon fill={theme.colors.red} />
                  </Box>
                  Export Wallet Key
                </Button>
              )}
              <div className=" social-icons">
                <DiscordIcon fill="#FFFFFF" />
                <InstagramIcon fill="#FFFFFF" />
                <TwitterIcon fill="#FFFFFF" />
              </div>
            </Box>
          </StyledMobileMenu>
        </StyledMobileMenuWrapper>
        <PrivateKeyExportModal
          show={privateKeyModal}
          setShow={setPrivateKeyModal}
        />
      </>
    );
  }
  return null;
};

const StyledMobileMenuWrapper = styled.div`
  position: absolute;
  top: 67px;
  right: 0;
  bottom: 0;
  left: 0;
  z-index: 1000;
  background: #000000;
  height: 100vh;
  // margin-left: 18px;
  // margin-right: 18px;
  // min-height:100vh;
  // max-height: 100vh;
  overflow: hidden;
  background-size: 100% 100%;
`;
const StyledBackdrop = styled.div`
  position: absolute;
  top: 0;
  right: 0;
  bottom: 0;
  left: 10%;
`;
const slideIn = keyframes`
  0% {
    transform: translateX(0)
  }
  100% {
    transform: translateX(-100%);
  }
`;
const StyledMobileMenu = styled.div`
  animation: ${slideIn} 0.3s forwards ease-out;
  display: flex;
  flex: 1;
  flex-direction: column;
  position: relative;
  top: 0;
  left: 100%;
  bottom: 0;
  width: 100%;
  height: 100%;
  max-width: 100%;
  overflow-y: scroll;
  font-weight: bold;
  font-family: "Inter", sans-serif;
  font-size: 24px;
  line-height: 36px;
  color: #ff8c03;
  padding: 20px 20px 40px 30px;

  .disabled {
    pointer-events: none;
    opacity: 0.4;
  }
  .social-icons {
    margin-top: 20px;
    svg {
      cursor: pointer;
      margin-right: 28px;
      &:last-child {
        margin-right: 0;
      }
    }
  }

  .nav-footer {
    width: 100%;
    margin: auto 0 100px 0;
  }
`;
const StyledLogoAndIcon = styled.div`
  display: flex;
  align-items: center;
  justify-content: flex-end;
  flex-direction: row;
`;

const StyledLink = styled(NavLink)`
  padding-top: 20px;
  text-transform: capitalize;
  font-family: "Montserrat";
  font-style: normal;
  letter-spacing: 0.01em;

  font-weight: 600;
  font-size: 36px;
  line-height: 140%;
  text-decoration: none;
  text-transform: capitalize;

  color: #ffffff;

  &.active {
    color: ${({ theme }) => theme.colors.blueText};
    -webkit-background-clip: text;
    background-clip: text;
    text-fill-color: transparent;
  }
  &:hover {
    text-decoration: none;
    color: ${({ theme }) => theme.colors.blueText};
  }

  ${({ theme }) => theme.mediaQueries.maxMd} {
    font-size: 24px;
  }
`;
export default MobileNavbar;
