import styled from "styled-components";

const Container = styled.div`
  margin-left: auto;
  margin-right: auto;
  max-width: 1281px;
  padding-left: 16px;
  padding-right: 16px;
  // background-image: url('./assets/images/Rectangle.png');
  background-size: 100% 100%;
  background-repeat: no-repeat;
  margin-bottom: 112px;
  padding-top: 80px;
  padding-bottom: 80px;

  &.aligned-container {
    background: unset;
    padding: 0;
    margin: 0px auto;
  }
  &.no-Background {
    background: unset;
    padding-top: 0px;
    padding-bottom: 0px;
    margin: 0px auto;
  }
  &.no-border {
    background-image: none;
  }
  @media (max-width: 1300px) {
    width: 95%;
  }
  ${({ theme }) => theme.mediaQueries.tab} {
    width: 93%;
    padding: 0;
    &.aboutContainer {
      padding: 0 !important;
    }
  }
  ${({ theme }) => theme.mediaQueries.sm} {
    padding-left: 63px;
    padding-right: 63px;
    &.no-Background {
      background: unset;
      padding-top: 0px;
      padding-bottom: 0px;
      margin: 0px auto;
      padding-left: 16px;
      padding-right: 16px;
    }
  }
  ${({ theme }) => theme.mediaQueries.tab} {
    width: 93%;
    &.aboutContainer {
      padding: 0 !important;
    }
  }
  ${({ theme }) => theme.mediaQueries.maxMd} {
    width: 93%;
    padding-top: 43px;
    padding-bottom: 23px;

    &.bannerContainer {
      background-image: unset;
      width: 100%;
    }
    &.featuresContainer {
      background: none;

      margin-bottom: 69px;
    }
    &.aboutContainer {
      background: none;
      margin-bottom: 46px;
      padding: 0;
      // background-image: url('./assets/images/About/aboutMobile.png');
      // background-size: 100% 100%;
      // background-repeat: no-repeat;
    }
    &.footerContainer {
      padding: 0;
    }
  }
`;

export default Container;
