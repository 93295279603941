import { Redirect } from "react-router-dom";
import { AuthContext } from "contexts/AuthContext";
import { useContext } from "react";

const ProtectedRoute = ({ children }: { children: JSX.Element }) => {
  const { user } = useContext(AuthContext);

  if (user && user?.isVerified) return children;

  return <Redirect to={"/signin"} />;
};

export default ProtectedRoute;
