import React from "react";
import styled from "styled-components";
import DiscordIcon from "components/Svg/Icons/Discord";
import InstagramIcon from "components/Svg/Icons/Instagram";
import TwitterIcon from "components/Svg/Icons/Twitter";
import { Box } from "components/Box";

const ComingSoonComponent = () => {
  return (
    <ComingSoonStyledComponent>
      <Box className="bannerText">
        {/* <Star className="left-star" />
                <Star className="right-star" /> */}
        <h1 className="title">
          Hold Tight! <br />
          This page is coming soon
        </h1>
        <p>In the meantime, Check out our Social Pages to stay up to date.</p>
        <Box className="social-icons">
          <DiscordIcon fill="#FFFFFF" />
          <InstagramIcon fill="#FFFFFF" />
          <TwitterIcon fill="#FFFFFF" />
        </Box>
      </Box>
      {/* <Box className="line">
                <Line />
            </Box> */}
    </ComingSoonStyledComponent>
  );
};

const ComingSoonStyledComponent = styled.div`
  min-height: 100vh;
  background-image: url("./assets/images/comingsoonbg.png");
  background-repeat: no-repeat, no-repeat;
  background-position: center center;
  background-size: cover;

  display: flex;
  align-items: center;
  justify-content: center;
  .bannerText {
    text-align: center;
    max-width: 90%;
    padding: 3rem;
    padding-top: 0;
    position: relative;
    margin: auto;
    .left-star {
      position: absolute;
      left: 0;
      bottom: 0;
    }
    .right-star {
      position: absolute;
      right: 0;
      top: 0;
    }
    .title {
      font-family: "Bebas Neue";
      font-style: normal;
      font-weight: 400;
      font-size: 120px;
      line-height: 120%;
      text-align: center;
      letter-spacing: 0.01em;
      color: #ffffff;
    }
    p {
      text-align: center;
      font-family: "Montserrat";
      font-style: normal;
      font-weight: 500;
      font-size: 24px;
      line-height: 120%;
      text-align: center;
      letter-spacing: 0.01em;
      color: #ffffff;
      width: 56%;
      margin: 0 auto;
    }
    .social-icons {
      display: flex;
      align-items: center;
      justify-content: center;
      margin-top: 46px;
      svg {
        margin-right: 33px;
        &:last-child {
          margin-right: 0;
        }
      }
    }
  }

  .line {
    text-align: center;
    // margin-top:50px;
  }
  @media (min-width: 1680px) {
    .bannerText {
      max-width: 80%;
    }
  }
  @media (min-width: 1920px) {
    .bannerText {
      max-width: 70%;
    }
  }
  ${({ theme }) => theme.mediaQueries.tab} {
    .bannerText {
      display: flex;
      flex-direction: column;
      align-items: center;
      justify-content: center;
      width: 100%;
      max-width: 100%;
    }
  }
  ${({ theme }) => theme.mediaQueries.maxMd} {
    .bannerText {
      .title {
        font-size: 30px;
      }
      p {
        font-size: 18px;
        width: 100%;
      }
    }
  }
`;
export default ComingSoonComponent;
