import Container from "components/layout/Container";
import React from "react";
import styled from "styled-components";
import { Box, Flex } from "components/Box";
import NewsCard from "./components/NewsCard";

interface Props {
  showTitle: boolean;
}
const Banner: React.FC<Props> = ({ showTitle }) => {
  const newsList = [
    {
      title: "Islamabad United enters Metaverse",
      desc: "Press Release Immediate Release February 4, 2023 The most successful PSL Franchise launches the world's first Cricketing Metaverse Islamabad United…",
      img: "./assets/images/news/news4.jpg",
      link: "https://www.islamabadunited.com/islamabad-united-enters-the-metaverse-and-offers-the-fans-unprecedented-experiences-with-free-digital-collectible-cards-coming-soon/",
    },
    {
      title: "Islamabad United launches PSL’s FIRST digital collection...",
      desc: "Press Release Immediate release 8th February, 2023 Islamabad United's first ever digital collectible will be GIFTED to its fans Sheru…",
      img: "./assets/images/news/news2.png",
      link: "https://www.islamabadunited.com/islamabad-united-launches-psls-first-digital-collection-as-a-gift-to-its-fans/",
    },
    {
      title:
        "Islamabad United's Metaverse Stadium, the U-Meta stadium, Now Available on Android",
      desc: "Press Release Immediate Release 6th of March, 2023 After successfully launching the world’s first cricketing metaverse...",
      img: "./assets/images/news/news3.jpg",
      link: "https://www.islamabadunited.com/islamabad-uniteds-metaverse-stadium-the-u-meta-stadium-now-available-on-android/",
    },
  ];
  return (
    <>
      <BannerStyledComponent className={`${!showTitle && "simple-title"}`}>
        <Container className="bannerContainer">
          <Flex flexDirection="column" alignItems="center" className="main-row">
            <Box className="bannerText text-center">
              Discover all the new of U-Meta stadium and IU Metaverse here
            </Box>
          </Flex>
        </Container>
      </BannerStyledComponent>
      <NewsCard data={newsList} hasBg={showTitle} />
    </>
  );
};

export default Banner;

const BannerStyledComponent = styled.div`
  background-image: url("./assets/images/stadiumBg.png");
  background-repeat: no-repeat, no-repeat;
  background-position: center center;
  background-size: cover;
  padding-top: 100px;

  .bannerContainer {
    margin-bottom: 0px;
    padding-top: 20px;
    padding-bottom: 20px;
  }
  .marignRight {
    margin-right: 16px;
  }
  .main-row {
    max-width: 100%;
  }

  .bannerText {
    font-family: "Bebas Neue";
    font-style: normal;
    font-weight: 400;
    font-size: 80px;
    line-height: 120%;
    text-align: center;
    letter-spacing: 0.01em;
    position: relative;
    max-width: 90%;
    padding: 3rem;
    color: #ffffff;
    &.simple-title {
    }
  }
  &.simple-title {
    background-image: none;
    background: #111314;
    .bannerText {
      font-size: 44px;
    }
  }
  ${({ theme }) => theme.mediaQueries.maxMd} {
    .main-row {
      align-items: flex-end !important;
    }

    .bannerText {
      font-size: 40px;
      width: 100%;
      max-width: 80%;
      padding: 0;
      margin: auto;
      .star-right {
        top: -40px;
        svg {
          width: 43px;
          height: 44px;
        }
      }
    }
    .line {
      margin: auto;
      margin-top: 46px !important;
    }
  }
`;
