import React from "react";
import Svg from "../Svg";
import { SvgProps } from "../types";

const ChainIcon: React.FC<SvgProps> = (props) => {
  return (
    <Svg
      width="18"
      height="18"
      viewBox="0 0 18 18"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M9.59028 14.3033L8.41177 15.4818C6.78459 17.109 4.1464 17.109 2.51922 15.4818C0.892032 13.8546 0.892032 11.2164 2.51922 9.58925L3.69773 8.41074M14.3043 9.58925L15.4828 8.41074C17.11 6.78355 17.11 4.14536 15.4828 2.51818C13.8557 0.890994 11.2175 0.890994 9.59028 2.51818L8.41177 3.69669M6.08436 11.9166L11.9177 6.08331"
        stroke="white"
        strokeWidth="2"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
    </Svg>
  );
};

export default ChainIcon;
