import { Box } from "components/Box";
import Container from "components/layout/Container";
import React from "react";
import { Col, Row } from "react-bootstrap";
import styled from "styled-components";

const NewsCard: React.FC<{ data: any; hasBg: boolean }> = ({ data, hasBg }) => {
  return (
    <DetailStyledComponent className={`${!hasBg && "has-bg"}`}>
      <Container className="card-container">
        <Row>
          {data.map((news, index) => (
            <Col key={index} md={4}>
              <Box
                className="card-wrapper"
                onClick={() => window.open(news.link)}
              >
                <Box className="img-wrapper">
                  <img src={news.img} alt="" />
                </Box>
                <Box className="text-center contentwrapper">
                  <Box className="bannerDetail mt-4">{`${news.title.slice(
                    0,
                    37
                  )} ...`}</Box>
                  <Box className="bannerdescription text-white mt-4">
                    {`${news.desc.slice(0, 110)} ...`}
                  </Box>
                </Box>
              </Box>
            </Col>
          ))}
        </Row>
      </Container>
    </DetailStyledComponent>
  );
};

export default NewsCard;

const DetailStyledComponent = styled.div`
  &.has-bg {
    background: #111314;
  }
  .bannerContainer {
    margin-bottom: 0px;
    padding-top: 20px;
    padding-bottom: 20px;
  }
  .card-container {
    padding-left: 0;
    padding-right: 0;
    margin-bottom: 0;
  }

  .card-wrapper {
    background: rgba(255, 255, 255, 0.1);
    border: 1px solid #505050;
    cursor: pointer;

    border-radius: 16px;
    padding: 10px;
    margin-bottom: 50px;
    min-height: 450px;
    .img-wrapper {
      width: 100%;
      height: 200px;
      border-radius: 10px;
      img {
        width: 100%;
        height: 100%;
        border-radius: inherit;
      }
    }
  }
  .contentwrapper {
    max-width: 800px;
    margin: 0 auto;
    padding: 0 12px;
    .bannerDetail {
      font-family: "Montserrat";
      font-style: normal;
      font-weight: 700;
      font-size: 24px;
      color: #ffffff;
      line-height: 1.2;
      text-align: start;
    }

    .bannerdescription {
      font-family: "Montserrat";
      font-style: normal;
      font-weight: 400;
      font-size: 16px;
      text-align: center;
      color: #fbfdfa;
      line-height: 24px;
      text-align: start;
    }
  }

  .img-wrapper {
    width: 500px;
    margin-left: auto;
    img {
      width: 100%;
    }
    margin-right: 10px;
  }
  .link {
    color: #39ff14;
    text-decoration: none;
    margin-right: 8px;
  }

  ${({ theme }) => theme.mediaQueries.maxMd} {
    .contentwrapper {
      .bannerDetail {
        font-size: 24px;
        text-align: center;
      }
    }
    .card-wrapper {
      margin-bottom: 72px;
    }
  }
`;
