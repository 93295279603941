import React from "react";
import Svg from "../Svg";
import { SvgProps } from "../types";

const ShareIcon: React.FC<SvgProps> = (props) => {
  return (
    <Svg
      width="44"
      height="44"
      viewBox="0 0 44 44"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      {...props}
    >
      <path
        d="M18.59 23.51L25.42 27.49M25.41 16.51L18.59 20.49M31 15C31 16.6569 29.6569 18 28 18C26.3431 18 25 16.6569 25 15C25 13.3431 26.3431 12 28 12C29.6569 12 31 13.3431 31 15ZM19 22C19 23.6569 17.6569 25 16 25C14.3431 25 13 23.6569 13 22C13 20.3431 14.3431 19 16 19C17.6569 19 19 20.3431 19 22ZM31 29C31 30.6569 29.6569 32 28 32C26.3431 32 25 30.6569 25 29C25 27.3431 26.3431 26 28 26C29.6569 26 31 27.3431 31 29Z"
        stroke="white"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <rect x="0.5" y="0.5" width="43" height="43" rx="11.5" stroke="white" />
    </Svg>
  );
};

export default ShareIcon;
