import React from "react";
import Svg from "../Svg";
import { SvgProps } from "../types";

const Aeroplane: React.FC<SvgProps> = (props) => {
  return (
    <Svg
      width="66"
      height="66"
      viewBox="0 0 66 66"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <rect
        x="5"
        y="5"
        width="56"
        height="56"
        rx="28"
        fill="#E22E31"
        fillOpacity="0.2"
      />
      <g clipPath="url(#clip0_34538_9178)">
        <path
          d="M31.9092 32.7016L26.8153 35.6425M26.8928 35.9578L28.4598 43.666C28.5829 44.2716 28.6444 44.5744 28.7956 44.6955C28.9269 44.8006 29.1 44.8378 29.2629 44.796C29.4505 44.7478 29.6311 44.4971 29.9923 43.9957L41.4487 28.0948C41.8013 27.6054 41.9776 27.3608 41.9653 27.1694C41.9545 27.0031 41.8669 26.8513 41.7283 26.7589C41.5687 26.6525 41.2686 26.6828 40.6685 26.7435L21.163 28.7153C20.55 28.7772 20.2435 28.8082 20.108 28.9463C19.9904 29.0662 19.9359 29.2345 19.961 29.4006C19.9899 29.5919 20.2201 29.7966 20.6806 30.2059L26.6228 35.4888C26.7019 35.5591 26.7414 35.5943 26.7728 35.6355C26.8007 35.672 26.8237 35.712 26.8413 35.7544C26.8611 35.8022 26.8717 35.8541 26.8928 35.9578Z"
          stroke="#E22E31"
          strokeWidth="2"
          strokeLinecap="round"
          strokeLinejoin="round"
        />
      </g>
      <rect
        x="5"
        y="5"
        width="56"
        height="56"
        rx="28"
        stroke="#E22E31"
        strokeOpacity="0.1"
        strokeWidth="10"
      />
      <defs>
        <clipPath id="clip0_34538_9178">
          <rect
            width="28"
            height="28"
            fill="white"
            transform="translate(19 19)"
          />
        </clipPath>
      </defs>
    </Svg>
  );
};
export default Aeroplane;
