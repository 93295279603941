import React, { lazy } from "react";
import { Router, Route, Switch, Redirect } from "react-router-dom";
import history from "./routerHistory";
import GlobalStyle from "./style/Global";
import PageLoader from "./components/PageLoader";
import SuspenseWithChunkError from "./components/SuspenseWithChunkError";
import "bootstrap/dist/css/bootstrap.min.css";
import "react-phone-number-input/style.css";

import Footer from "components/Footer";
import ComingSoon from "views/ComingSoon";
import Header from "components/Header";
import Whitelist from "views/Whitelist";
import News from "views/News";
import ProtectedRoute from "components/ProtectedRoutes";
import ScrollEffect from "components/ScrollEffect";

const Home = lazy(() => import("./views/Home"));
const Stadium = lazy(() => import("./views/Stadium"));
const FAQS = lazy(() => import("./views/FAQs"));
const SignIn = lazy(() => import("./views/SignIn"));
const Dashboard = lazy(() => import("./views/Dashboard"));
const NFTDetails = lazy(
  () => import("./views/Dashboard/components/NFTDetails")
);
const Profile = lazy(() => import("./views/Profile"));
const SignUp = lazy(() => import("./views/SignUp"));

const App: React.FC = () => {
  React.useEffect(() => {
    document
      .querySelectorAll(".button")
      .forEach(
        (button) =>
          (button.innerHTML =
            "<div><span>" +
            button.textContent.trim().split("").join("</span><span>") +
            "</span></div>")
      );
  }, []);
  return (
    <Router history={history}>
      <GlobalStyle />
      <Header />
      <SuspenseWithChunkError fallback={<PageLoader />}>
        <Switch>
          <Route path="/" exact>
            <Home />
          </Route>
          <Route path="/stadium" exact>
            <Stadium />
          </Route>
          <Route path="/coming-soon" exact>
            <ComingSoon />
          </Route>
          <Route path="/iu-metaverse" exact>
            <ComingSoon />
          </Route>
          <Route path="/news" exact>
            <News showTitle={true} />
          </Route>
          <Route path="/whitelisting" exact>
            <Whitelist />
          </Route>
          <Route path="/faqs" exact>
            <FAQS />
          </Route>
          <Route path="/signin" exact>
            <ScrollEffect>
              <SignIn />
            </ScrollEffect>
          </Route>
          <Route path="/signup" exact>
            <SignUp />
          </Route>

          <Route path="/dashboard" exact>
            <ProtectedRoute>
              <Dashboard />
            </ProtectedRoute>
          </Route>
          <Route path="/dashboard/nft/:id" exact>
            <ProtectedRoute>
              <NFTDetails />
            </ProtectedRoute>
          </Route>
          <Route path="/profile" exact>
            <ProtectedRoute>
              <Profile />
            </ProtectedRoute>
          </Route>

          <Redirect from="*" to="/" />
        </Switch>
        <Footer />
      </SuspenseWithChunkError>
    </Router>
  );
};

export default App;
