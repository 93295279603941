import React from "react";
import styled from "styled-components";
import FacebookIcon from "components/Svg/Icons/Facebook";
import DiscordIcon from "components/Svg/Icons/Discord";
import InstagramIcon from "components/Svg/Icons/Instagram";
import TwitterIcon from "components/Svg/Icons/Twitter";

const SocialMediaIcons: React.FC = () => {
  return (
    <FooterSocailIconComponent className="sectionOne">
      <div className="images-section">
        <FacebookIcon
          onClick={() => window.open("https://www.facebook.com/IsbUnitedPak/")}
        />
        <DiscordIcon
          onClick={() => window.open("https://discord.com/invite/TD4k38Rk5A")}
        />

        <InstagramIcon
          onClick={() =>
            window.open("https://www.instagram.com/islamabadunitedpsl/")
          }
        />
        <TwitterIcon
          onClick={() => window.open("https://twitter.com/IsbUnited")}
        />
      </div>
    </FooterSocailIconComponent>
  );
};

export default SocialMediaIcons;

const FooterSocailIconComponent = styled.div`
  .heading {
    font-family: "Aldrich";
    font-style: normal;
    font-weight: 400;
    font-size: 20px;
    line-height: 140%;
    text-transform: uppercase;
    color: #ffffff;
    margin-bottom: 18px;
  }
  .images-section {
    svg {
      margin-right: 26px;
      cursor: pointer;
      &:last-child {
        margin-right: 0;
      }
    }
  }
  ${({ theme }) => theme.mediaQueries.maxMd} {
    .heading {
      font-size: 16px;
      line-height: 150%;
    }
  }
`;
