import React from "react";
// import { STORAGE_KEY } from "config/constants";

interface INFTContext {
  nftData: any;
  setNFTData: React.Dispatch<any>;
}
export const NFTContext = React.createContext<INFTContext>(null!);

const NFTProvider = ({ children }: any) => {
  const [nftData, setNFTData] = React.useState(null);

  return (
    <NFTContext.Provider value={{ nftData, setNFTData }}>
      {children}
    </NFTContext.Provider>
  );
};

export default NFTProvider;
