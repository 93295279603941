import { PageMeta } from "./types";

export const DEFAULT_META: PageMeta = {
  title: "U-Meta",
  description: `The world's first cricket metaverse stadium`,
  image: "",
};

export const customMeta: { [key: string]: PageMeta } = {
  "/": {
    title: "U-Meta",
  },
};
