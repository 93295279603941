import React from "react";
import { HelmetProvider } from "react-helmet-async";
import { Provider } from "react-redux";
import { ThemeContextProvider } from "contexts/ThemeContext";
import { RefreshContextProvider } from "contexts/RefreshContext";
import store from "state";
import { getLibrary } from "utils/web3React";

import { ModalProvider } from "components/Modal";
import { ErrorBoundaryWeb3ProviderNetwork } from "utils/ErrorBoundaryWeb3ProviderNetwork";
import { Web3ReactProvider } from "@web3-react/core";
import AuthProvider from "contexts/AuthContext";
import NFTProvider from "contexts/NFTContext";
import { Toaster } from "react-hot-toast";
import AppLogout from "components/AutoLogout";
import { ParallaxProvider } from "react-scroll-parallax";

const Providers: React.FC = ({ children }) => {
  return (
    <Web3ReactProvider getLibrary={getLibrary}>
      <ErrorBoundaryWeb3ProviderNetwork>
        <Provider store={store}>
          <Toaster position={"top-right"} />
          <HelmetProvider>
            <ThemeContextProvider>
              <RefreshContextProvider>
                <AuthProvider>
                  <ParallaxProvider>
                    <NFTProvider>
                      <AppLogout>
                        <ModalProvider>{children}</ModalProvider>
                      </AppLogout>
                    </NFTProvider>
                  </ParallaxProvider>
                </AuthProvider>
              </RefreshContextProvider>
            </ThemeContextProvider>
          </HelmetProvider>
        </Provider>
      </ErrorBoundaryWeb3ProviderNetwork>
    </Web3ReactProvider>
  );
};

export default Providers;
