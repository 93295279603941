import * as CryptoJS from "crypto-js";

/**
 * Decrypt a string using the secret key
 * @param text - string to decrypt
 * @param password - secret key
 * @return {string} decrypted string
 */
export const decryptText = (text: string, password: string) => {
  const bytes = CryptoJS.AES.decrypt(text, password);
  return bytes.toString(CryptoJS.enc.Utf8);
};
