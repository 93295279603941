import React from "react";
import { NavLink } from "react-router-dom";
import styled from "styled-components";
import NavigationTabArray from "utils/navigationTab";
import { useContext } from "react";
import { AuthContext } from "contexts/AuthContext";
import { useLocation } from "react-router";

const Nav: React.FC = () => {
  const { user } = useContext(AuthContext);
  const { pathname } = useLocation();
  return (
    <StyledNav>
      {NavigationTabArray.map((data, index) => (
        <React.Fragment key={index}>
          {user && data?.name === "ISLU Digital Collectibles" ? (
            <>
              <StyledLink
                key={index}
                exact
                to={"/dashboard"}
                activeClassName="active"
                className={"padding-high"}
              >
                {data.name}
              </StyledLink>
            </>
          ) : (
            <>
              <StyledLink
                key={index}
                exact
                to={data?.route}
                activeClassName="active"
                className={`padding-high ${
                  pathname === "/signin" &&
                  data.name === "ISLU Digital Collectibles"
                    ? "active"
                    : ""
                }`}
              >
                {data.name}
              </StyledLink>
            </>
          )}
        </React.Fragment>
      ))}
    </StyledNav>
  );
};

const StyledNav = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  width: 100%;
  .disabled {
    pointer-events: none;
    opacity: 0.4;
  }
`;
const StyledLink = styled(NavLink)`
  text-decoration: none;
  font-family: "Montserrat";
  font-style: normal;
  font-weight: 600;
  font-size: 18px;
  line-height: 140%;
  letter-spacing: 0.01em;
  color: #ffffff;
  padding: 10px 20px;
  position: relative;
  transition: all 0.3s ease;
  transform: translate3d(0px, 0px, 0px);
  box-sizing: border-box;
  &:after {
    content: "";
    position: absolute;
    width: calc(100% - 40px);
    height: 2px;
    left: 50%;
    bottom: 0;
    background-color: #04d9ff;
    // transform:translate(-50%, 0%);
    transform-origin: 125% 0%;
    transform: scaleX(0) translate(-50%, -50%);
    // transform:translate(-50%, -50%);
    transition: transform 0.3s ease-out;
  }
  &:hover,
  &.active {
    color: #04d9ff !important;
    &:after {
      transform-origin: -25% 0%;
      transform: scaleX(1) translate(-50%, -50%);
    }
  }
  //  &:hover, &.active {
  //   &:after {
  //     content:'';
  //     background: #39FF14;
  //     height:2px;
  //     width:50%;
  //     position: absolute;
  //     bottom:0;
  //     left:50%;
  //     transform: translate(-50%, -50%) ;
  //     transition: all 0.3s ease;
  //     margin:auto;
  //   }
  // }
  // :last-child {
  //   padding-right: 0px;
  // }
  &:hover {
    text-decoration: none;
    color: #ffffff;
  }
  @media (max-width: 1100px) {
    &.padding-high {
      padding: 0 1.3%;
    }
    &.padding-low {
      padding: 0 13px;
    }
  }
  ${({ theme }) => theme.mediaQueries.maxXl} {
    font-size: 16px;

    padding: 10px 10px !important;
  }
`;

export default Nav;
