import React, { Component } from "react";
import { createWeb3ReactRoot } from "@web3-react/core";
import { getLibrary } from "utils/web3React";

export class ErrorBoundaryWeb3ProviderNetwork extends Component {
  constructor(props) {
    super(props);
    this.state = { hasError: false };
  }

  static getDerivedStateFromError() {
    // Update state so the next render will show the fallback UI.
    return { hasError: true };
  }
  render() {
    let Web3ProviderNetwork;
    try {
      Web3ProviderNetwork = createWeb3ReactRoot("NETWORK");
    } catch (e) {
      return <>{this.props.children}</>;
    }
    if (this.state["hasError"]) {
      return <>{this.props.children}</>;
    }
    return (
      <Web3ProviderNetwork getLibrary={getLibrary}>
        {this.props.children}
      </Web3ProviderNetwork>
    );
  }
}
