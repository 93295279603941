import React from "react";
import styled from "styled-components";
import ComingSoonComponent from "./components/ComingSoonComponent";

const Home: React.FC = () => {
  return (
    <>
      <WarrperBanner>
        {/* <Header /> */}
        <ComingSoonComponent />
      </WarrperBanner>
    </>
  );
};

export default Home;

const WarrperBanner = styled.div`
  overflow: hidden;
`;
