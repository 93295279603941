import { Box } from "components/Box";
import { Text } from "components/Text";
import { Modal } from "react-bootstrap";
import styled from "styled-components";
import React, { useContext, useEffect, useState } from "react";
import { AeroplaneIcon, ClipBoardIcon, CloseIcon } from "./Svg";
import { AuthContext } from "contexts/AuthContext";
import OtpInput from "react-otp-input";
import Loader from "./Svg/Loader";
import { Button } from "./Button";
import toast from "react-hot-toast";
import Toast from "./Toast";
import axios from "axios";
import { BASE_URL } from "../config";
import { decryptText } from "../utils/decrypt";
import useTheme from "hooks/useTheme";

type IModalProps = {
  show: boolean;
  setShow: React.Dispatch<React.SetStateAction<boolean>>;
};

const PrivateKeyExportModal: React.FC<IModalProps> = ({ show, setShow }) => {
  const { user } = useContext(AuthContext);
  const [otp, setOtp] = useState("");
  const [isKeyVisible, setIsKeyVisible] = useState(false);
  const [loading, setLoading] = useState(false);
  const [key, setKey] = useState("");
  const { theme } = useTheme();

  useEffect(() => {
    const sendOTP = async () => {
      try {
        await axios.get(`${BASE_URL}/user/key/otp`, {
          headers: {
            Authorization: user?.token,
          },
        });
      } catch (e: any) {
        toast.custom(() => (
          <Toast
            message={
              e?.response?.data?.message ||
              "An error occurred while sending OTP. Try again later"
            }
            type={"error"}
          />
        ));
      }
    };

    if (show) {
      setOtp("");
      setIsKeyVisible(false);
      void sendOTP();
    }
  }, [user?.token, show]);

  const verifyOtp = async () => {
    setLoading(true);
    try {
      const response: any = await axios.post(
        `${BASE_URL}/user/key/export`,
        {
          otp,
        },
        {
          headers: {
            Authorization: user?.token,
          },
        }
      );
      if (response?.status === 201) {
        setIsKeyVisible(true);
        setKey(response?.data?.data?.privateKey);
      }
    } catch (e: any) {
      toast.custom(() => (
        <Toast
          message={
            e?.response?.data?.message ||
            "An error occurred while sending OTP. Try again later"
          }
          type={"error"}
        />
      ));
    } finally {
      setLoading(false);
    }
  };

  const copy = () => {
    navigator.clipboard.writeText(decryptText(key, otp)).then(() => {
      toast.custom(() => (
        <Toast message={"Private key copied successfully!"} type={"success"} />
      ));
    });
  };

  const handleChange = (otp: string) => {
    setOtp(otp);
  };
  return (
    <CustomModal
      show={show}
      aria-labelledby="contained-modal-title-vcenter"
      centered
      size="sm"
    >
      <ModalBody>
        <Box
          className={"w-100 d-flex justify-content-end"}
          onClick={() => setShow(false)}
        >
          <CloseIcon fontSize={"26px"} fill={theme.colors.disabledInputField} />
        </Box>
        <Box className="img-wrapper">
          <AeroplaneIcon />
        </Box>
        <Text className="heading">Export private key</Text>
        <Text className="description">
          {isKeyVisible
            ? `This is your private key`
            : `We have just sent you a verification code at ${user?.email}. Please enter PIN code to verify.`}
        </Text>

        {isKeyVisible && (
          <Button
            variant={"text"}
            onClick={copy}
            startIcon={
              <Box className={"me-2"}>
                <ClipBoardIcon fill={theme.colors.red} />
              </Box>
            }
          >
            Copy Private Key
          </Button>
        )}

        {isKeyVisible ? (
          <Box className={"key-container"} onClick={(e) => console.log(e)}>
            <Text className="private-key">{decryptText(key, otp)}</Text>
          </Box>
        ) : (
          <OtpInput
            value={otp}
            onChange={handleChange}
            numInputs={6}
            inputStyle="otp-input"
            isInputNum={true}
            shouldAutoFocus={true}
          />
        )}

        <Box className={"alert"}>
          <Text className="text">
            Warning: Never disclose this key. Anyone with your private keys can
            steal any assets held in your account.
          </Text>
        </Box>

        {isKeyVisible ? (
          <Box className={"d-flex w-100"}>
            <Button
              type="submit"
              className={"w-100 button"}
              onClick={() => setShow(false)}
            >
              Done
            </Button>
          </Box>
        ) : (
          <Box className={"d-flex w-100"}>
            <Button
              type="submit"
              className={"w-100 me-2 button button-secondary"}
              onClick={() => setShow(false)}
            >
              Cancel
            </Button>
            <Button
              type="submit"
              className={"w-100 button"}
              onClick={verifyOtp}
              disabled={otp.length < 6}
            >
              {loading && <Loader color={"#fff"} />}Confirm
            </Button>
          </Box>
        )}
      </ModalBody>
    </CustomModal>
  );
};

export default PrivateKeyExportModal;

const ModalBody = styled(Modal.Body)`
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: column;
  min-width: 420px;
  background: ${({ theme }) => theme.colors.white};
  border: 12px solid ${({ theme }) => theme.colors.red};
  border-radius: 16px;

  .otp-input {
    max-width: 52px !important;
    width: 100% !important;
    height: 64px;
    border-radius: 8px;
    border: 1px solid #494949;
    margin-right: 10px;
  }

  .heading {
    font-family: "Bebas Neue";
    font-style: normal;
    font-weight: 400;
    font-size: 36px;
    line-height: 38px;
    text-align: center;
    color: ${({ theme }) => theme.colors.black};
    margin-bottom: 12px;
  }

  .description {
    font-family: "Source Sans Pro";
    font-style: normal;
    font-weight: 400;
    font-size: 18px;
    line-height: 24px;
    text-align: center;
    color: #9f9f9f;
    margin-bottom: 24px;
  }

  .img-wrapper {
    width: 100%;
    justify-content: center;
    display: flex;
    margin-bottom: 22px;
  }

  .key-container {
    border: solid 1px ${({ theme }) => theme.colors.black};
    border-radius: 8px;
    padding: 10px 11px;
    margin-top: 20px;
    width: 100%;
    .private-key {
      color: ${({ theme }) => theme.colors.black};
      font-family: "Source Sans Pro";
      font-size: large;
      overflow-wrap: anywhere;
    }
  }

  .alert {
    background-color: #ffdfdf;
    border-radius: 8px;
    padding: 10px 11px;
    margin-top: 30px;
    .text {
      color: ${({ theme }) => theme.colors.red};
      font-family: "Source Sans Pro";
    }
  }

  .button {
    margin-top: 10px;
    width: 100%;
    border-radius: 8px;
    color: ${({ theme }) => theme.colors.white};
    font-family: "Bebas Neue";
    font-style: normal;
    font-weight: 400;
    font-size: 24px;
    line-height: 24px;
    text-transform: uppercase;
  }
  .button-secondary {
    background: ${({ theme }) => theme.colors.white};
    border: 1px solid ${({ theme }) => theme.colors.black};
    color: ${({ theme }) => theme.colors.black};
  }

  ${({ theme }) => theme.mediaQueries.maxMd} {
    width: 100%;
    margin: 0;
  }

  ${({ theme }) => theme.mediaQueries.maxSm} {
    width: 100%;
    margin: 0;
    min-width: 100%;
  }
`;

const CustomModal = styled(Modal)`
  width: 100%;
  height: 100%;
  background: rgba(0, 0, 0, 0.8);

  .modal-content {
    background: none !important;
  }
`;
