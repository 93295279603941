import { Flex } from "./Box";
import styled from "styled-components";

const StyledToast = styled(Flex)`
  padding: 0.6rem 1rem;
  background: white;
  border-radius: 4px;
  transition: 0.3s;
  align-items: center;
  justify-content: center;
  box-shadow: rgba(255, 255, 255, 0.3) 0 5px 15px 0;
  & p {
    font-family: "Montserrat", sans-serif;
    font-weight: 500;
    margin: 0 0 0 0.4rem;
    font-size: large;
  }
`;

const Toast = ({ message, type }) => {
  return (
    <StyledToast>
      {type === "success" ? (
        <svg
          xmlns="http://www.w3.org/2000/svg"
          width="24"
          height="24"
          viewBox="0 0 24 24"
          strokeWidth="2"
          stroke="green"
          fill="none"
          strokeLinecap="round"
          strokeLinejoin="round"
        >
          <path stroke="none" d="M0 0h24v24H0z" fill="none"></path>
          <path d="M12 12m-9 0a9 9 0 1 0 18 0a9 9 0 1 0 -18 0"></path>
          <path d="M9 12l2 2l4 -4"></path>
        </svg>
      ) : (
        <svg
          xmlns="http://www.w3.org/2000/svg"
          width="24"
          height="24"
          viewBox="0 0 24 24"
          strokeWidth="2"
          stroke="red"
          fill="none"
          strokeLinecap="round"
          strokeLinejoin="round"
        >
          <path stroke="none" d="M0 0h24v24H0z" fill="none"></path>
          <path d="M12 12m-9 0a9 9 0 1 0 18 0a9 9 0 1 0 -18 0"></path>
          <path d="M10 10l4 4m0 -4l-4 4"></path>
        </svg>
      )}

      <p>{message}</p>
    </StyledToast>
  );
};

export default Toast;
