import Container from "components/layout/Container";
import React from "react";
import { useLocation } from "react-router-dom";
import styled from "styled-components";
import SocialMediaIcons from "./components/SocialMedia";
import { useHistory } from "react-router-dom";
import { StaticRoutes } from "utils/staticRoutes";

const Footer: React.FC = () => {
  const history = useHistory();
  const { pathname } = useLocation();
  if (
    pathname === "/coming-soon" ||
    pathname === "/iu-metaverse" ||
    pathname === "/sheru-army" ||
    pathname === "/profile" ||
    pathname === "/dashboard" ||
    pathname === "/signin" ||
    pathname.includes("/dashboard/nft")
  ) {
    return null;
  }
  return (
    <Container className="aligned-container footerContainer">
      <FooterStyledComponent>
        <div className="sectionTwo">
          <img src={"./assets/images/Umeta/logo.svg"} alt="" />
          <ul>
            <li onClick={() => history.push(StaticRoutes.STADIUM)}>Stadium</li>
            <li onClick={() => history.push(StaticRoutes.SIGNIN)}>
              ISLU Digital Collectibles
            </li>
            <li onClick={() => history.push(StaticRoutes.NEWS)}>News</li>
            <li onClick={() => history.push(StaticRoutes.FAQS)}>FAQ</li>
            <li onClick={() => history.push(StaticRoutes.COMING_SOON)}>
              Privacy
            </li>
            <li onClick={() => history.push(StaticRoutes.COMING_SOON)}>
              Terms and Conditions
            </li>
            <li onClick={() => history.push(StaticRoutes.COMING_SOON)}>
              Legal Warning
            </li>
          </ul>
        </div>
        <div className="lower-div">
          <p className="copyright-txt">
            © 2023 U-Meta Stadium. All rights reserved.
          </p>
          <SocialMediaIcons />
        </div>
      </FooterStyledComponent>
    </Container>
  );
};

export default Footer;

const FooterStyledComponent = styled.div`
  margin-top: 64px;
  overflow: hidden;
  .sectionOne {
    .heading {
      font-family: "Aldrich";
      font-style: normal;
      font-weight: 400;
      font-size: 20px;
      line-height: 140%;
      text-transform: uppercase;
      color: #ffffff;
      margin-bottom: 18px;
    }
    .images-section {
      img {
        margin-right: 13px;
        cursor: pointer;
      }
    }
  }
  .sectionTwo {
    display: flex;
    justify-content: space-between;
    align-items: center;
    ul {
      li {
        display: inline-block;
        list-style-type: none;
        font-family: "Montserrat";
        font-weight: 700;
        font-style: normal;
        font-size: 16px;
        line-height: 24px;
        cursor: pointer;
        color: ${({ theme }) => theme.colors.secondaryTextColor};
        margin-right: 32px;
        &:last-child {
          margin-right: 0;
        }
        position: relative;
        &:after {
          content: "";
          position: absolute;
          width: 100%;
          height: 2px;
          left: 0px;
          bottom: 0;
          background-color: #04d9ff;
          // transform:translate(-50%, 0%);
          transform-origin: 125% 0%;
          transform: scaleX(0);
          transition: transform 0.3s ease-out;
        }
        &:hover,
        &.active {
          color: #04d9ff !important;
          &:after {
            transform-origin: -25% 0%;
            transform: scaleX(1);
          }
        }
      }
    }
  }
  .lower-div {
    display: flex;
    align-items: center;
    justify-content: space-between;
    border-top: 1px solid ${({ theme }) => theme.colors.primaryBorderColor};
    margin-top: 44px;
    padding: 32px 0 50px 0;
    .copyright-txt {
      font-family: "Inter Regular", sans-serif;
      font-style: normal;
      font-size: 16px;
      line-height: 24px;
      color: ${({ theme }) => theme.colors.tertiaryTextColor};
    }
  }
  @media (max-width: 992px) {
    .sectionTwo {
      flex-direction: row;

      li {
        font-size: 12px !important;
      }
    }
  }
  ${({ theme }) => theme.mediaQueries.maxXl} {
    .sectionTwo {
      li {
        margin-top: 20px;
        font-size: 14px !important;
      }
    }
  }

  ${({ theme }) => theme.mediaQueries.tab} {
    .sectionTwo {
      li {
        font-size: 13px !important;
      }
    }
  }

  ${({ theme }) => theme.mediaQueries.maxMd} {
    .sectionTwo {
      flex-direction: column;
      justify-content: flex-start;
      align-items: flex-start;

      img {
        margin-bottom: 48px;
        width: 144px;
        margin-left: 24px;
      }
      ul {
        padding-left: 24px;
      }
      li {
        width: 100%;
        margin-bottom: 32px;
        font-size: 16px !important;
      }
    }
    .lower-div {
      flex-direction: column;
      justify-content: flex-start;
      align-items: flex-start;
      margin: 0 24px;
    }
  }
`;
