import React, { useEffect } from "react";
import { otpVerify } from "../rest/otp-verify";
import { identicon } from "../utils/identicon";

interface IAuthContext {
  user: any;
  setUser: React.Dispatch<any>;
  login: (
    otp: string,
    fieldType: { type: string; value: string }
  ) => Promise<void>;
}
export const AuthContext = React.createContext<IAuthContext>(null!);

const AuthProvider = ({ children }: any) => {
  const [user, setUser] = React.useState(() => {
    const user = localStorage.getItem(":user");
    return user ? JSON.parse(user) : null;
  });

  const login = async (otp, fieldType) => {
    try {
      const res: any = await otpVerify({
        otp,
        [fieldType.type]: fieldType.value,
      });
      const user = { ...res?.data?.data?.user, token: res?.data?.data?.token };
      if (!user?.profilePicture) {
        const image = identicon(user?.walletAddress || user?.email);
        user.profilePicture = `data:image/svg+xml,${image}`;
      }
      setUser(user);
    } catch (e) {
      throw e;
    }
  };

  useEffect(() => {
    if (user && user?.isVerified) {
      localStorage.setItem(":user", JSON.stringify(user));
    }
  }, [user]);

  return (
    <AuthContext.Provider value={{ user, setUser, login }}>
      {children}
    </AuthContext.Provider>
  );
};

export default AuthProvider;
