import React from "react";
import Svg from "../Svg";
import { SvgProps } from "../types";

const LogoutIcon: React.FC<SvgProps> = (props) => {
  return (
    <Svg
      width="20"
      height="14"
      viewBox="0 0 20 14"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      {...props}
    >
      <path
        d="M12.0523 10C11.0437 11.7934 9.1585 13 6.99935 13C3.77769 13 1.16602 10.3137 1.16602 7.00002C1.16602 3.6863 3.77769 1 6.99935 1C9.1585 1 11.0437 2.2066 12.0523 4.00001"
        stroke="white"
        strokeWidth="2"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M15.3327 3.57141L18.666 6.99999M18.666 6.99999L15.3327 10.4286M18.666 6.99999H6.99935"
        stroke="white"
        strokeWidth="2"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
    </Svg>
  );
};

export default LogoutIcon;
