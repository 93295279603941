import React from "react";
import { Redirect, useHistory } from "react-router-dom";
import { useEffect } from "react";
import { useContext } from "react";
import { AuthContext } from "contexts/AuthContext";

const Whitelist: React.FC = () => {
  const history = useHistory();
  const { user } = useContext(AuthContext);

  useEffect(() => {
    if (user) {
      history.push("/profile");
    }
  }, [user, history]);

  return <Redirect to={"/signup"} />;
};

export default Whitelist;
