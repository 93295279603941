import React from "react";
import Svg from "../Svg";
import { SvgProps } from "../types";

const Icon: React.FC<SvgProps> = (props) => {
  return (
    <Svg width="13" height="13" viewBox="0 0 13 13" fill="none" {...props}>
      <path
        className="svg"
        d="M11 3.4V11C11 11.2833 11.0957 11.5207 11.287 11.712C11.479 11.904 11.7167 12 12 12C12.2833 12 12.521 11.904 12.713 11.712C12.9043 11.5207 13 11.2833 13 11V1C13 0.716667 12.9043 0.479 12.713 0.287C12.521 0.0956668 12.2833 0 12 0H2C1.71667 0 1.47933 0.0956668 1.288 0.287C1.096 0.479 1 0.716667 1 1C1 1.28333 1.096 1.521 1.288 1.713C1.47933 1.90433 1.71667 2 2 2H9.6L0.700001 10.9C0.516667 11.0833 0.424999 11.3167 0.424999 11.6C0.424999 11.8833 0.516667 12.1167 0.700001 12.3C0.883334 12.4833 1.11667 12.575 1.4 12.575C1.68333 12.575 1.91667 12.4833 2.1 12.3L11 3.4Z"
        fill="#04D9FF"
      />
    </Svg>
  );
};

export default Icon;
