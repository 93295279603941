import React from "react";
import Svg from "../Svg";
import { SvgProps } from "../types";

const BulletsIcon: React.FC<SvgProps> = (props) => {
  return (
    <Svg
      width="16"
      height="16"
      viewBox="0 0 16 16"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      {...props}
    >
      <path
        fillRule="evenodd"
        d="M16.0001 7.89833L8.00003 0L0 7.89833L8.00003 15.7967L16.0001 7.89833ZM12.3637 7.89831L8.00003 3.59014L3.63639 7.89831L8.00003 12.2065L12.3637 7.89831Z"
        fill="white"
      />
    </Svg>
  );
};

export default BulletsIcon;
