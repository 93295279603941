import React from "react";
import Svg from "../Svg";
import { SvgProps } from "../types";

const Lock: React.FC<SvgProps> = (props) => {
  return (
    <Svg
      width="1em"
      height="1em"
      viewBox="0 0 20 20"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      {...props}
    >
      <path
        d="M14.8975 16.7419C13.4738 17.7763 11.7591 18.3334 9.99928 18.3334C8.23945 18.3334 6.5248 17.7763 5.10107 16.7419M13.6524 2.51009C15.2341 3.28155 16.531 4.53394 17.3572 6.08778C18.1834 7.64161 18.4964 9.41712 18.2515 11.1598M1.74712 11.1597C1.5022 9.41704 1.81527 7.64153 2.64146 6.0877C3.46765 4.53386 4.76454 3.28147 6.34627 2.51001M9.52787 5.47145L5.47068 9.52864C5.30567 9.69364 5.22317 9.77615 5.19226 9.87129C5.16507 9.95497 5.16507 10.0451 5.19226 10.1288C5.22317 10.2239 5.30567 10.3064 5.47068 10.4714L9.52787 14.5286C9.69288 14.6936 9.77538 14.7761 9.87052 14.8071C9.95421 14.8343 10.0444 14.8343 10.128 14.8071C10.2232 14.7761 10.3057 14.6936 10.4707 14.5286L14.5279 10.4714C14.6929 10.3064 14.7754 10.2239 14.8063 10.1288C14.8335 10.0451 14.8335 9.95497 14.8063 9.87129C14.7754 9.77615 14.6929 9.69364 14.5279 9.52864L10.4707 5.47145C10.3057 5.30644 10.2232 5.22394 10.128 5.19302C10.0444 5.16583 9.95421 5.16583 9.87052 5.19302C9.77538 5.22394 9.69288 5.30644 9.52787 5.47145Z"
        stroke={props.fill || "white"}
        strokeWidth="2"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
    </Svg>
  );
};
export default Lock;
