export enum StaticRoutes {
  STADIUM = "/stadium",
  WHITELISTING = "/whitelisting",
  NEWS = "/news",
  FAQS = "/faqs",
  SIGNIN = "/signin",
  SIGNUP = "/signup",
  DASHBOARD = "/dashboard",
  NFTID = "/dashboard/nft/:id",
  PROFILE = "/profile",
  PRIVACY = "",
  TERMS = "",
  LEGAL = "",
  COMING_SOON = "/coming-soon",
}
