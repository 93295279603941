import { Box, Flex } from "components/Box";
import { Text } from "components/Text";
import { Modal } from "react-bootstrap";
import styled from "styled-components";
import React from "react";
import { META_URL } from "config";

type ISignUpModalProps = {
  show: boolean;
  onHide: () => void;
};

const UMetaModal: React.FC<ISignUpModalProps> = ({ show, onHide }) => {
  return (
    <CustomModal
      show={show}
      size="md"
      aria-labelledby="contained-modal-title-vcenter"
      centered
      onHide={onHide}
    >
      <ModalBody>
        <Text className="heading">Where do you want to play?</Text>
        <Flex justifyContent={"space-around"} className="mobile-flex">
          <Box className={"wrapper"}>
            <Flex
              className="card"
              flexDirection={"column"}
              onClick={() => {
                onHide();

                window.open(META_URL, "_blank");
              }}
            >
              <img src="assets/images/desktop-mana.svg" alt="ethereum" />
              <Text className="name">DECENTRALAND</Text>
            </Flex>
          </Box>
          <Box className={"wrapper"}>
            <Flex
              className="card"
              flexDirection={"column"}
              onClick={() => {
                onHide();
                window.open(
                  "https://play.google.com/store/apps/details?id=com.ideofuzion.u_meta",
                  "_blank"
                );
              }}
            >
              <img src="assets/images/mobile-mana.svg" alt="ethereum" />
              <Text className="name">Mobile</Text>
            </Flex>
          </Box>
        </Flex>
      </ModalBody>
    </CustomModal>
  );
};

export default UMetaModal;

const ModalBody = styled(Modal.Body)`
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: column;
  min-width: 630px;
  background: ${({ theme }) => theme.colors.white};
  border: 12px solid ${({ theme }) => theme.colors.red};
  border-radius: 16px;

  .heading {
    font-family: "Bebas Neue";
    font-style: normal;
    font-weight: 400;
    font-size: 36px;
    line-height: 38px;
    text-align: center;
    color: ${({ theme }) => theme.colors.black};
    margin: 30px 0 40px;
  }
  .wrapper {
    background: #dadada;
    padding: 2px;
    margin: 0 10px 50px;
    border-radius: 16px;
    transition: all 0.1s linear;
    &:hover {
      background: linear-gradient(
          91.38deg,
          #91d7ff -0.52%,
          #9d6fff 49.22%,
          #ff8282 100%
        ),
        linear-gradient(0deg, #ffffff, #ffffff);
      box-shadow: 0px 16px 28px rgba(40, 116, 140, 0.25);
    }
  }
  .card {
    width: 250px;
    height: 270px;
    border-radius: 16px;
    justify-content: space-around;
    align-items: center;
    cursor: pointer;

    img {
    }
  }

  .name {
    font-family: "Bebas Neue";
    font-style: normal;
    font-weight: 400;
    font-size: 28px;
    line-height: 38px;
    text-align: center;
    color: ${({ theme }) => theme.colors.black};
  }

  ${({ theme }) => theme.mediaQueries.maxMd} {
    width: 100%;
    margin: 0;
    .mobile-flex {
      flex-direction: column;
    }
  }
  ${({ theme }) => theme.mediaQueries.maxSm} {
    width: 100%;
    margin: 0;
    min-width: 100%;
  }
`;

const CustomModal = styled(Modal)`
  width: 100%;
  height: 100%;
  background: rgba(0, 0, 0, 0.8);

  margin: auto !important;

  .modal-content {
    background: none !important;
    margin: auto !important;
  }
`;
