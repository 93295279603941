import { StaticRoutes } from "./staticRoutes";

interface NavigationTab {
  name: string;
  route: string;
  disabled: boolean;
  diffColor?: boolean;
}
const NavigationTabArray: NavigationTab[] = [
  {
    name: "Stadium",
    route: StaticRoutes.STADIUM,
    disabled: false,
  },
  {
    name: "ISLU Digital Collectibles",
    route: StaticRoutes.SIGNIN,
    disabled: false,
  },
  {
    name: "News",
    route: StaticRoutes.NEWS,
    disabled: false,
  },
];

export default NavigationTabArray;
