import React from "react";
import Svg from "../Svg";
import { SvgProps } from "../types";
import useTheme from "../../../hooks/useTheme";

const Icon: React.FC<SvgProps> = (props) => {
  const { theme } = useTheme();
  return (
    <Svg
      xmlns="http://www.w3.org/2000/svg"
      width="1em"
      height="1em"
      viewBox="0 0 24 24"
      stroke-width="3"
      stroke={theme.colors.success}
      fill="none"
      strokeLinecap="round"
      strokeLinejoin="round"
      {...props}
    >
      <path stroke="none" d="M0 0h24v24H0z" fill="none"></path>
      <path d="M12 12m-9 0a9 9 0 1 0 18 0a9 9 0 1 0 -18 0"></path>
      <path d="M9 12l2 2l4 -4"></path>
    </Svg>
  );
};

export default Icon;
